<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader
            :title="headercontent.title"
            :addlink="headercontent.addlink"
          />
        </v-col>
        <v-col md="12">
          <h3 class="filtertitle">Filter {{ headercontent.title }}</h3>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="fullname"
                  outlined
                  label="Name"
                  class="mr-btm-minus"
                ></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-select
                v-if="allCategory.results"
                  :items="allCategory.results.list"
                  v-model="category"
                  item-text="title"
                  item-value="categoryHandle"
                  label="Job Category"
                  multiple
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  :items="lookingfors"
                  v-model="lookingfor"
                  label="Job Level"
                  outlined
                  multiple
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  :items="educations"
                  v-model="education"
                  label="Education"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  :items="availableFors"
                  v-model="availableFor"
                  label="Available For"
                  outlined
                ></v-select>
              </v-col>

              <v-col cols="12" class="submitbtn">
                <v-btn
                  :disabled="!valid"
                  class="submitbutton btn-dark"
                  @click="validate"
                >
                  Filter {{ headercontent.title }}
                </v-btn>
                <!-- <v-progress-circular
         
                  indeterminate
                ></v-progress-circular> -->
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col md="12">
          <v-simple-table class="table" v-if="!allLoadingResume">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">S/N</th>
                  <th class="text-left">Resume Code</th>
                  <th class="text-left">Full Name</th>
                  <th class="text-left">Job Categoies</th>
                  <th class="text-left">Job Level</th>
                  <th class="text-left">Education</th>
                  <th class="text-right" colspan="2">Action</th>
                </tr>
              </thead>
              <tbody v-if="allResume.results != undefined">
                <tr
                  v-for="(item, index) in allResume.results.list"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.basicInformation.resume_number }}</td>
                  <td>{{ item.basicInformation.fullname }}</td>
                  <td>
                    <ul v-if="item.jobPreference != null">
                      <li
                        v-for="(data, index) in item.jobPreference.jobCategoris"
                        :key="index"
                      >
                      <template v-if="index<1">{{ data }}</template>
                        
                      </li>
                    </ul>
                  </td>
                  <td>
                    <span v-if="item.jobPreference != null">{{ item.jobPreference.lookingFor }}</span>
                  </td>
                  <td>
                    <span
                      v-for="(data, index) in item.education"
                      :key="index + 'ski'"
                    >
                      {{ data.degree }}
                    </span>
                  </td>
                  <td class="text-right">
                    <router-link
                      :to="
                        '/dashboard/resume/add/' +
                        item.basicInformation.resume_number
                      "
                      ><v-icon class="icons-action">
                        mdi-pencil
                      </v-icon></router-link
                    >
                    <!-- <v-icon class="icons-action"> mdi-delete </v-icon> -->
                    <v-icon
                      class="icons-action"
                      @click="
                        singleResumeView(item.basicInformation.resume_number)
                      "
                    >
                      mdi-download
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div
            v-if="
              allResume.results &&
              allResume.results.pagination.total <= 0 &&
              !allLoadingResume
            "
            class="nodatabox"
          >
            No Data Found
          </div>
          <div v-if="allLoadingResume">
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
          </div>
          <ul class="pagination-button" v-if="allResume.results != undefined">
            <li>
              Page :
              <span v-if="allResume.results.pagination.next">{{
                (allResume.results.pagination.next - 1) * this.limit
              }}</span
              ><span v-if="!allResume.results.pagination.next">{{
                allResume.results.pagination.total
              }}</span>
              -
              {{ allResume.results.pagination.total }}
            </li>
            <li>
              <div>
                <v-btn
                  v-if="allResume.results.pagination.previous"
                  @click="prevPage(allResume.results.pagination.previous)"
                  class="btn-dark"
                  >Prev</v-btn
                >

                <v-btn
                  v-if="allResume.results.pagination.next"
                  @click="nextPage(allResume.results.pagination.next)"
                  class="btn-dark"
                  >Next</v-btn
                >
              </div>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
    <ResumeView :showbox="showbox" @closepopup="closepopup" />
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import ResumeView from "@/components/ResumeView";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Resume",
  components: { PageHeader, ResumeView },
  data: () => ({
    headercontent: {
      title: "Resume List",
      addlink: "/dashboard/resume/add",
    },
    showbox: true,
    fullname: "",
    adsposition: "",
    valid: true,
    educations: ["SLC", "10+2", "Bachelor", "Master", "PHD"],
    availableFor: "",
    lookingfor: "",
    lookingfors: ["Top Level", "Senior Level", "Mid Level", "Entry Level"],
    availableFors: [
      "Full Time",
      "Part Time",
      "Temporary",
      "Contract",
      "Freelance",
      "Traineership",
      "Internship",
      "Volunteer",
    ],
    category: "",
    education: "",
    skip: 1,
    limit: 20,
    filter: {},
  }),
  methods: {
    ...mapActions([
      "getAllResume",
      "fetchCategory",
      "filterResume",
      "singelResume",
    ]),
    validate() {
      this.filter = {};
      if (this.fullname != "") {
        this.filter = Object.assign(
          {
            "basicInformation.fullname": {
              $regex: this.fullname,
              $options: "i",
            },
          },
          this.filter
        );
      }
      if (this.category != "") {
        this.filter = Object.assign(
          { "jobPreference.jobCategoris": this.category },
          this.filter
        );
      }
      if (this.availableFor != "") {
        this.filter = Object.assign(
          { "jobPreference.available_for": this.availableFor },
          this.filter
        );
      }
      if (this.lookingfor != "") {
        this.filter = Object.assign(
          { "jobPreference.lookingFor": this.lookingfor },
          this.filter
        );
      }
      if (this.education != "") {
        this.filter = Object.assign(
          { "education.degree": this.education },
          this.filter
        );
      }

      this.filterResume({
        resumefilter: JSON.stringify(this.filter),
        ...{ limit: this.limit, skip: this.skip },
      });
      // console.log(filter);
    },
    nextPage(data) {
      this.filterResume({
        resumefilter: JSON.stringify(this.filter),
        ...{ limit: this.limit, skip: data },
      });
    },
    prevPage(data) {
      this.filterResume({
        resumefilter: JSON.stringify(this.filter),
        ...{ limit: this.limit, skip: data },
      });
    },
    singleResumeView(data) {
      this.showbox = true;
      this.singelResume(data);
    },
    closepopup(e) {
      this.showbox = e;
    },
  },
  computed: {
    ...mapGetters([
      "allResume",
      "allCategory",
      "allLoadingResume",
      "allSingleResume",
    ]),
  },
  created() {
    this.getAllResume({ limit: this.limit, skip: this.skip });
    this.fetchCategory();
    this.showbox = false;


  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.nodatabox {
  width: 100%;
  text-align: center;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
  padding: 15px 0;
}
.form-section {
  padding: 15px 0;
  .col {
    padding: 0 10px;
  }
}
.filtertitle {
  margin-bottom: 10px;
}
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  margin-top: -10px;
}

.table {
  box-shadow: 0px -1px 5px 2px rgba(0, 0, 0, 0.1);

  thead {
    border-bottom: 2px solid $menucolor;
    background: $tagcolor;
  }
  th {
    font-size: $normal_lfont !important;
    color: $white !important;
  }
}
.pagination-button {
  display: flex;
  list-style: none;
  padding-left: 0;
  justify-content: space-between;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
  .btn-dark {
    margin-top: 0;
    margin-left: 10px;
  }
}
.table-heading,
.pagination-button {
  border: 1px solid $menucolor;
  margin-bottom: 0;
  padding: 10px;
  align-items: center;
}
.icons-action {
  margin: 10px;
  font-size: $iconfont;
}
</style>
